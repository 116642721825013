




































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';

@Component
export default class KpiSummaryComponent extends mixins(BaseMixin) {
  @Prop({ required: true })
  public numberOfClicks!: number;

  @Prop({ required: true })
  public numberOfUsers!: number;

  @Prop({ required: true })
  public usedBudget!: number;

  @Prop()
  public totalBudget?: number;

  @Prop()
  public remainingBudget?: number;

  @Prop({ default: false })
  public isLoading!: boolean;

  private getProgressBarColor(value: number): string {
    if (value <= BaseMixin.THRESHOLD_RED) {
      return 'error';
    } else if (value >= BaseMixin.THRESHOLD_GREEN) {
      return 'success';
    } else {
      return 'warning';
    }
  }
}
